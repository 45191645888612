<template>
    <div class="container_layout_tabela_padrao">
        <div class="container_breadcrumb">
            <slot name="breadcrumb">
                <Breadcrumb titulo="-" class="bg-light" />
            </slot>
        </div>

        <div class="container_tabela d-flex flex-column m-3 p-3">
            <section class="tabela_menu">
                <slot name="menu">Menu</slot>
            </section>

            <section class="tabela_listagem bg-white">
                <slot name="tabela"
                      :classesPadrao="'app-scroll-custom mb-0 h-100'"
                      :propsPadrao="tabelaProps"
                      :colunasEstiloPadrao="colunasEstiloPadrao">
                    Tabela
                </slot>
            </section>

            <section class="tabela_paginacao">
                <slot name="paginacao">Paginação</slot>
            </section>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'

    export default {
        name: 'LayoutTabela',
        components: {
            Breadcrumb
        },
        props: {
            colunasTabelas: [Array]
        },
        computed: {
            tabelaProps() {
                return {
                    'small': true,
                    'striped': true,
                    'responsive': true,
                    'borderless': true,
                    'no-border-collapse': true,
                    'sort-icon-left': true
                }
            },
            colunasEstiloPadrao() {
                if (this.colunasTabelas) {
                    const estilo = { thStyle: 'border-inline: 12px solid transparent', tdClass: 'px-3' }
                    this.colunasTabelas[0] = { ...this.colunasTabelas[0], ...estilo }
                    return this.colunasTabelas
                }
                return ''
            }
        }
    }
</script>

<style scoped>
    .container_layout_tabela_padrao {
        height: 100%;
    }

    .container_breadcrumb {
        height: 50px;
        max-height: 50px;
    }

    .container_tabela {
        background-color: var(--cinza-3);
        height: calc(100% - 139px);
    }

    .tabela_menu {
        height: 40px;
    }

    .tabela_listagem {
        height: calc(100% - 70px);
    }

    .tabela_paginacao {
        height: 30px;
    }
</style>